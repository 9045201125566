input[type='checkbox'] {
    display: none;
}

.lbl-toggle {
    display: block;

    font-weight: bold;
    font-family: monospace;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;

    padding: 1rem;

    color: var(--hightlighted-menu-color);

    cursor: pointer;
    transition: all .5s ease-out;
}

.lbl-toggle::before {
    content: '';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .25s ease-out;
}

.collapsible-content .content-inner {
    margin-top: -1rem;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    /* padding: .5rem 1rem; */
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 100vh;
}

.toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}