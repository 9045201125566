#darkMode {
    background: url("../assets/images/sun-small.png") no-repeat center;
    background-size: 30px 30px;
    margin-top: .4rem;
    width: 40px;
    height: 40px;
    border: none;
    transition: 0.5s ease-in-out;
    outline: none;
  }
  
  #darkMode:hover,
  #darkMode:focus {
    filter: none;
    cursor: pointer;
  }
  
  #darkMode.clicked {
    background: url("../assets/images/pale-moon.png") no-repeat center;
    transform: rotate(360deg);
    background-size: 35px  35px;
  }